<script setup>
const nuxtApp = useNuxtApp();
const { getPageBySlug } = useWpApi();
const { data, error } = await getPageBySlug("home", nuxtApp);

if (
  error.value
  // ||
  // data.value.length === 0 ||
  // (!isNaN(data.value?.length) && !data.value.length)
) {
  throw createError({
    statusCode: 404,
    statusMessage: process.dev
      ? "Page Not Found - index.vue"
      : "Page Not Found",
  });
}

watch(
  () => data.value,
  (newVal) => {
    nextTick(() => {
      useWpSeo(data);
    });
  },
  { immediate: true }
);
</script>

<template>
  <div class="page page--index">
    <PageModules v-if="data?.acf" :modules="data.acf.page_modules" />
  </div>
</template>

<style lang="postcss" scoped></style>
